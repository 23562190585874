<template>
  <b-card>
    <h4 class="mb-0">
      Edit Data Referensi
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="employee_id" rules="required">
              <b-form-group label="Pegawai" label-for="employee_id" :state="errors.length > 0 ? false : null">
                <v-select id="employee_id" v-model="dataStudent.employee_id" :reduce="(employee_id) => employee_id.id"
                  placeholder="Pegawai" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai"
                  label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Tanggal Efektif" label-for="effective_date">
              <validation-provider #default="{ errors }" name="effective_date" rules="required">
                <b-form-input id="effective_date" v-model="dataStudent.effective_date"
                  :state="errors.length > 0 ? false : null" placeholder="Tanggal Efektif" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Group" label-for="first_title">
              <validation-provider #default="{ errors }" name="group" rules="required">
                <b-form-input id="group" v-model="dataStudent.group" :state="errors.length > 0 ? false : null"
                  placeholder="Gelar Awal" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nama Bank" label-for="bank_name">
              <validation-provider #default="{ errors }" name="bank_name" rules="required">
                <b-form-input id="bank_name" v-model="dataStudent.bank_name" :state="errors.length > 0 ? false : null"
                  placeholder="bank_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nomor Rekening" label-for="bank_account_number">
              <validation-provider #default="{ errors }" name="bank_account_number" rules="required">
                <b-form-input id="bank_account_number" v-model="dataStudent.bank_account_number"
                  :state="errors.length > 0 ? false : null" placeholder="Nomor Rekening" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nama Pemilik Rekening" label-for="bank_account_name">
              <validation-provider #default="{ errors }" name="bank_account_name" rules="required">
                <b-form-input id="bank_account_name" v-model="dataStudent.bank_account_name"
                  :state="errors.length > 0 ? false : null" placeholder="Nama Pemilik Rekening" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="NPWP" label-for="npwp_number">
              <validation-provider #default="{ errors }" name="npwp_number" rules="required">
                <b-form-input id="npwp_number" v-model="dataStudent.npwp_number" :state="errors.length > 0 ? false : null"
                  placeholder="Nama Institusi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="tax_status" rules="required">
              <b-form-group label="NIP" label-for="tax_status" :state="errors.length > 0 ? false : null">
                <v-select id="tax_status" v-model="dataStudent.tax_status" :reduce="(tax_status) => tax_status.id"
                  placeholder="Status Pajak" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listTaxStatus"
                  label="tax_status" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Back
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    name: '',
    listPegawai: [],
    listTaxStatus: [],

    dataStudent: {
      employee_id: '',
      effective_date: '',
      group: '',
      bank_name: '',
      bank_account_number: '',
      bank_account_name: '',
      npwp_number: '',
      tax_status: '',
    },

    dataIsActive: [
      { value: 1, text: 'Aktif' },
      { value: 0, text: 'Tidak Aktif' },
    ],
  }),
  computed: {
  },
  created() {
    this.getStudentDetail()
    this.gettaxstatus()
    this.getpegawai()
  },
  methods: {
    async gettaxstatus() {
      try {
        const response = await this.$http.get('/taxstatuses')
        this.listTaxStatus = response.data.data
        console.log(response.data.data);
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
        console.log(response.data.data);
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/salaryreferences/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.employee_id = data.employee_id
      this.dataStudent.effective_date = data.effective_date
      this.dataStudent.group = data.group
      this.dataStudent.bank_name = data.bank_name
      this.dataStudent.bank_account_number = data.bank_account_number
      this.dataStudent.bank_account_name = data.bank_account_name
      this.dataStudent.npwp_number = data.npwp_number
      this.dataStudent.tax_status = data.tax_status
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/salaryreferences/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Pegawai Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'data-referensi' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Pegawai Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
